import React from "react";
import classes from "../styles/videos.module.css";
import Layout from "../components/Layout";
import { StylesProvider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";

const title = "Videos | Avenue Barber";
const description = "Check out our video gallery.";

export default function Videos({ data, location }) {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />

        <meta property="og:title" content={title} />

        <meta property="og:description" content={description} />

        {/* <meta property="og:image" content="LINK TO THE IMAGE FILE" /> */}

        {/* <meta property="og:url" content="PERMALINK" /> */}

        <meta property="og:site_name" content="Avenue Barber" />
        <link rel="canonical" href="https://avenuebarber.ca/videos" />
      </Helmet>
      <StylesProvider injectFirst>
        <Layout data={data} location={location}>
          <div className={classes.videoContainer}>
            <iframe
              width="1120"
              height="630"
              src="https://www.youtube.com/embed/_Hyyc_LJ_i4?si=5kvAKGLC2LTtdpWl"
              title="Avenue Barber Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </Layout>
      </StylesProvider>
    </>
  );
}

export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logo-mobile.png" }) {
      childImageSharp {
        fixed(width: 96, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    desktopLogo: file(relativePath: { eq: "logo-desktop.png" }) {
      childImageSharp {
        fixed(width: 256, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`;
